#klaro {
	.klaro {
		a {
			color: #54BAB3;
		}

		.cookie-notice:not(.cookie-modal-notice) {
			background-color: $white;
			border-radius: 0;
			box-shadow: 0 16px 24px rgba($black, 0.08);

			.cn-body {
				padding: .75rem 1.5rem 1.5rem 1.5rem;

				p {
					@include font-size(1rem);
					line-height: 1.5;
					color: $black;
					margin-bottom: 1rem;

					strong {
						color: $black;
					}
				}
			}
		}

		.cn-ok {
			align-items: flex-end;

			.cm-link {
				@extend .btn;
				@extend .btn-sm;
				@extend .btn-primary;

				margin-right: 0;
				order: 2;
				flex: 0 0 auto;
				@include font-size(.75rem);
			}

			.cn-buttons {
				order: 1;
				margin-top: 0;

				button {
					@extend .btn;
					@extend .btn-sm;
					@extend .btn-primary;

					@include font-size(.75rem);
				}
			}
		}

		.cookie-modal {
			.cm-modal {
				background: $white;
				border-radius: 0;

				.hide {
					svg {
						stroke: $black;
					}
				}

				.cm-header {
					border-bottom: 1px solid $border-color;
				}
				.cm-footer {
					border-top: 1px solid $border-color;

					&-buttons {
						button {
							@extend .btn;
							@extend .btn-primary;
						}
					}

					.cm-powered-by {
						display: none;
					}
				}

				.cm-header,
				.cm-body,
				.cm-footer {
					padding: 1.5rem;

					.title {
						font-family: $font-family-base;
						color: $black;
					}

					p {
						@include font-size(1rem);
						line-height: 1.5;
						color: $black;
						margin-bottom: 1rem;

						strong {
							color: $black;
						}
					}

					.cm-purposes {
						.cm-list-input:checked {
							& + .cm-list-label .slider {
								background-color: #54BAB3;
							}
						}

						.cm-list-title {
							color: $black;
                            font-size: 1rem;
                            font-weight: 500;
							margin-top: .375rem;
						}
					}

                    .cm-apps {
                        display: none;
                    }
				}

			}
		}
	}
}

.side-panel {
    z-index: 9999;

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    transition: visibility 0s 0.3s;
    text-align: left;
    //&::after {
    //    /* overlay layer */
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100%;
    //    background: transparent;
    //    cursor: pointer;
    //    //transform: translate3d(100%, 0, 0);
    //    //transition: background $general-transition-time 0s;
    //}
    &.--is-visible {
        visibility: visible;
        transition: visibility 0s 0s;
        transform: translateX(0);
        overflow: auto;

        //&::after {
        //    background: rgba(255, 255, 255, .75);
        //    transition: background 0.3s 0s;
        //}
    }

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        transition: visibility 0s 0.3s;
        text-align: left;
        &::after {
            /* overlay layer */
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            cursor: pointer;
            //transform: translate3d(100%, 0, 0);
            //transition: background $general-transition-time 0s;
        }
        &.--is-visible {
            visibility: visible;
            transition: visibility 0s 0s;
            transform: translateX(0);
            overflow: auto;

            &::after {
                background: rgba(255, 255, 255, .75);
                transition: background 0.3s 0s;
            }
        }
    }
    /* header */
    &__title {
        margin-top: 3rem;
        width: 100%;
    }
    &__header {
        display: flex;

        h2 {
            flex: 1 1 auto;
            margin-bottom: 2rem;
            padding-right: 4rem;
            @include media-breakpoint-up(md) {
                padding-right: 3rem;
            }
        }

        button {
            flex: 0 0 auto;
            border-radius: $border-radius;
        }
    }

    /* close */
    &__close {
        //display: none;
        position: absolute;
        top: 1rem;
        right: 4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        overflow: hidden;
        text-indent: 100%;
        padding: 0;
        border: 0;
        color: white;
        background-color: $primary;
        cursor: pointer;



        @include media-breakpoint-up(md) {
            position: absolute;
            top: 1rem;
            right: 4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            overflow: hidden;
            text-indent: 100%;
            padding: 0;
            border: 0;
            color: white;
            background-color: $primary;
            cursor: pointer;
        }

        @include media-breakpoint-down(md) {
            right: 1rem;
        }
    }

    /* container */
    &__container {
        background: $white;
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100%;
            max-width: 68%;
            height: 100%;
            top: 0;
            right: 0;

            z-index: 1;
            overflow: auto;
            /* smooth scrolling on touch devices */
            -webkit-overflow-scrolling: touch;
            transition: transform $general-transition-time 0s;
            transform: translate3d(100%, 0, 0);
            &.--position-left {
                right: auto;
                left: 0;
                transform: translate3d(-100%, 0, 0);
            }
            .--is-visible & {
                transform: translate3d(0, 0, 0);
                transition-delay: 0s;
            }
        }
        @include media-breakpoint-up(md) {
            width: 70%;
        }
        @include media-breakpoint-up(xl) {
            width: 48%;
        }
        @include media-breakpoint-up(xxl) {
            width: 48%;
        }
    }

    /* content */
    &__content {
        flex: 1 0 60%;
        color: $body-color;
        @include media-breakpoint-up(md) {
            padding: 3rem;
        }
        @include media-breakpoint-up(xl) {
            padding: 4.5rem;
        }
        @include media-breakpoint-up(xxl) {
            padding: 6.5rem;
        }
    }

    &__bottom {
        flex: 0 0 40%;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column-reverse;
        @include media-breakpoint-up(md) {
            flex: 0 0 6rem;
            background-color: $gray-100;
            padding: 2rem;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .btn-more {
            @include media-breakpoint-up(md) {
                padding-left: 0;
            }
        }
    }
}

/* Custom fonts */

@font-face {
    font-family: 'TiemposHeadline';
    src: url('../../fonts/subset-TiemposHeadline-Medium.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/subset-TiemposHeadline-Medium.ttf'),
    url('../../fonts/subset-TiemposHeadline-Medium.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'TiemposText-Medium';
    src: url('../../fonts/subset-TiemposText-Medium.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/subset-TiemposText-Medium.ttf'),
    url('../../fonts/subset-TiemposText-Medium.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'TiemposText-Regular';
    src: url('../../fonts/subset-TiemposText-Regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/subset-TiemposText-Regular.ttf'),
    url('../../fonts/subset-TiemposText-Regular.woff');
    font-weight: 400;
}

@font-face {
    font-family: 'TiemposText-RegularItalic';
    src: url('../../fonts/subset-TiemposText-RegularItalic.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/subset-TiemposText-RegularItalic.ttf'),
    url('../../fonts/subset-TiemposText-RegularItalic.woff');
    font-weight: 400;
}

@font-face {
    font-family: 'TiemposText-Semibold';
    //src: url('../../fonts/subset-TiemposText-Semibold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/subset-TiemposText-Semibold.ttf'),
    url('../../fonts/subset-TiemposText-Semibold.woff');
    font-weight: 600;
}

/* ------------------------------------------------------------------------------------------ */
/* General styles & overrides */

body {
    font-family: 'TiemposText-Regular', serif;
    background-color: #ffffff;
    color: #40434D;
    margin: 0;
    padding: 0;
}

a {
    color: #54BAB3;
}

a:hover {
    color: #1A262E;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    color: #ffffff;
    font-family: arboria, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    border: 2px solid #54BAB3;
    background-color: #54BAB3;
    letter-spacing: 0.64px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #1A262E;
    border: 2px solid #1A262E;
}

.btn-lg {
    padding: 1rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
}

.btn-link {
    color: #54BAB3;
    font-family: arboria, sans-serif;
    font-weight: 500;
    font-size: 1.05rem;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0;
    letter-spacing: 1px;
}

.btn-link:hover {
    color: #1A262E;
}

/* ------------------------------------------------------------------------------------------ */
/* Text styles */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.24;
    font-family: 'TiemposHeadline', serif;
    color: #1A262E;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'TiemposHeadline', serif;
}

h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

h2 {
    font-size: 3.5rem;
    font-weight: 500;
}

h3 {
    font-size: 2.5rem;
}

h4 {
    font-size: 2rem;
}

h5 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

h6 {
    font-size: 1rem;
    margin-bottom: 1.2rem;
}

p {
    font-family: 'TiemposText-Regular', sans-serif;
    font-size: 1.25rem;
    color: #40434D;
}

p.small {
    font-family: 'TiemposText-Regular', sans-serif;
    font-size: 1rem;
    color: #40434D;
}

/* ------------------------------------------------------------------------------------------ */
/* Navigation overrides */

.bg-light {
    background-color: transparent!important;
}

.navbar {
    padding: .5rem 0;
}

/* ------------------------------------------------------------------------------------------ */
/* Additional CSS box styles */

.pl-6,
.pl-md-6 {
    padding-left: 6rem !important;
}

.pt-8 {
    padding-top: 6rem !important;
}

.pt-6 {
    padding-top: 4rem !important;
}

.mb-8 {
    margin-bottom: 6rem !important;
}

.mt--8 {
    @include media-breakpoint-up(md) {
        margin-top: -2rem !important;
    }
}

/* ------------------------------------------------------------------------------------------ */
/* Let's go! */
.hero {
    @include media-breakpoint-up(md) {
        min-height: 800px;
    }
}

.hero span.upper {
    font-size: 2rem;
    color: #54BAB3;
    padding-left: 6rem;
    margin-bottom: 0rem;
    display: block;
}

.hero p {
    line-height: 2rem;
    margin-bottom: 3rem;
}

small {
    color: #6D6E74;
    font-size: 0.88rem;
    display: block;
    line-height: 1.8;
}

hr {
    margin-top: 5rem;
    margin-bottom: 4rem;
    border: 0;
    border-top: 2px solid #EAEFF5;
}

/* ------------------------------------------------------------------------------------------ */
/* Forms */

.form-wrapper {
    display: block;
    margin-top: 0em;
    padding: 4rem 6rem;
    background-color: #EAEFF5;
    font-family: arboria, sans-serif;
    font-weight: 500;
    font-style: normal;

    .error {
        font-family: arboria, sans-serif;
        font-weight: 500;
        color: red;
        font-size: 1rem;
        padding-top: 1rem;
        margin: 0;
    }
}
form {
    //display: block;
    //margin-top: 0em;
    //padding: 4rem 6rem;
    //background-color: #EAEFF5;
    //font-family: arboria, sans-serif;
    //font-weight: 500;
    //font-style: normal;
}

form p {
    font-size: 1rem;
    color: #40434D;
    margin-bottom: 1.5rem!important;
    line-height: 1.75rem!important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2em + 1.5rem);
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0;
}

.form-group {
    margin-bottom: 1.32rem;
}

.form-group label {
    font-weight: 500;
    font-family: arboria, sans-serif;
    display: inline-block;
    margin-bottom: .25rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.form-group input {
    font-weight: 400;
}

.form-group input:focus, textarea:focus {
    border-color: #000;
}

.form-check {
    margin-bottom: 0rem;
}

.form-check-label {
    text-transform: none!important;
    letter-spacing: 0!important;
    font-weight: 500;
}

.end-of-universe {
    z-index: -999;
    position: relative;
}

.go-up {
    margin-top: -410px;
    z-index: 999;
    position: relative;
}

/* ------------------------------------------------------------------------------------------ */
/* Calculator */

.hero-content {
    padding: 3rem 4rem 4rem 1rem;
    background-color: #ffffff;
    margin-top: -280px;
}

.calc {
    background-color: #EAEFF5;
    padding: 6rem 0 12rem;
    margin-bottom: 9rem;
    background-repeat: no-repeat;
    background-position: calc(40% + 320%) calc(100% + 80%)    ;
    background-size: 30%;
}

.calc .upper-title {
    text-transform: uppercase;
    font-size: 0.88rem;
    letter-spacing: 1px;
    font-family: arboria, sans-serif;
    font-weight: 500;
    font-style: normal;
    display: block;
    margin-bottom: 0.1rem;
    line-height: 1;
}

.calc .number {
    font-size: 4rem;
    font-family: 'TiemposText-Medium';
    line-height: 2rem;
}

.calc small {
    font-family: arboria, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 1rem;
    display: block;
}

.calculator {
    padding: 0;
}

.calculator .form-control {
    height: calc(1.25em + 1.5rem);
    padding: 2.8rem 1rem;
    font-size: 3rem;
    font-weight: 500;
    color: #54bab3;
    background-color: #fff;
    border-bottom: 4px solid #54bab3;
    font-family: 'TiemposText-Medium';
}

.presignin .form-control {
    height: calc(1.25em + 1.5rem);
    padding: 2.8rem 1rem;
    font-size: 3rem;
    font-weight: 500;
    color: #54bab3;
    background-color: #fff;
    border-bottom: 4px solid #54bab3;
    font-family: 'TiemposText-Medium';
}

hr.calc {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    border-top: 2px solid #d8dee5!important;
}

.calc hr {
    border-top: 4px solid #d8dee5;
}

/* ------------------------------------------------------------------------------------------ */
/* Call Me CTA */

.call-me {
    margin-top: -280px;
}

.atmo-img {
    position: relative;
    left: 5rem;
    z-index: 100;
}

.form-back {
    background-color: #1A262E;
    position: relative;
    margin-top: 8rem;
    padding: 10rem;
}

.form-back h1,h4 {
    color: #ffffff;
    margin-bottom: 4rem;
}

.form-back form {
    padding: 0;
    background-color: #1A262E;
}

.form-back .form-group label {
    color: #ffffff;
}

.call-me .btn-primary:hover {
    border: 2px solid #ffffff;
}

/* ------------------------------------------------------------------------------------------ */
/* Footer */

footer {
    padding: 0 0 4rem;
}

a.social-icon {
    padding: 0.8rem 1rem!important;
    border: 2px solid #54bab3;
    text-align: center;
    margin: 0 0 0 0.4rem;
}

a.social-icon:hover {
    border: 2px solid #1A262E;
}

/* ------------------------------------------------------------------------------------------ */
/* Drawer */
.side-panel__container {
    //background-color: $primary;
    //margin-left: 52%;
    //width: 48%;
    //height: 100vh;
    padding: 1rem 4rem 2rem;

    @include media-breakpoint-down(md) {
        padding: 1rem 1rem 2rem;
    }
    box-shadow: 0 0 40px rgba(0,0,0,0.12);

    hr {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
    p {
        color: #6D6E74;
        font-size: 1.15rem;
        line-height: 1.88rem;
        margin-bottom: 1rem;
    }
    h4 {
        color: #1A262E;
        margin-bottom: 1.5rem;
    }
    form {
        margin-top: 2rem;
        padding: 0;
        background-color: $white;
    }
    .social-icon {
        padding: 0;
        border: 0;
        width: 2rem;
        height: 2rem;

        &:hover {
            border: 0;
        }
    }
}

.drawer {
    background-color: #ffffff;
    //margin-left: 52%;
    //width: 48%;
    //height: 100vh;
    //padding: 1rem 4rem 2rem;
    box-shadow: 0 0 40px rgba(0,0,0,0.12);
}

.drawer hr {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.drawer p {
    color: #6D6E74;
    font-size: 1.15rem;
    line-height: 1.88rem;
    margin-bottom: 1rem;
}

.drawer form {
    margin-top: 2rem;
    padding: 0;
    background-color: #ffffff;
}

.drawer .social-icon {
    padding: 0;
    border: 0;
    width: 2rem;
    height: 2rem;
}

.drawer .social-icon:hover {
    border: 0;
}

.drawer h4 {
    color: #1A262E;
    margin-bottom: 1.5rem;
}

/* ------------------------------------------------------------------------------------------ */
/* Media Queries */

@media (min-width: 1200px) {

    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1304px;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .drawer {
        margin-left: 28%;
        width: 72%;
    }

    .go-up {
        margin-top: 48px;
        z-index: 999;
        position: relative;
    }

    .end-of-universe img {
        margin-top: -32px;
    }


}

@media (min-width: 768px) and (max-width: 991.98px) {

    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 90%;
    }

    .drawer {
        margin-left: 0;
        width: 100%;
    }

    .go-up {
        margin-top: 48px;
        z-index: 999;
        position: relative;
    }

    .end-of-universe img {
        margin-top: -32px;
    }

    hr {
        margin-top: 4rem;
        margin-bottom: 3rem;
    }

    .hero-content {
        padding: 2rem 1rem 3rem 1rem;
        background-color: #ffffff;
        margin-top: 0px;
    }

    .mt--8 {
        margin-top: 0;
    }

    .hero p {
        line-height: 2rem;
        margin-bottom: 0;
    }

    .atmo-img {
        position: relative;
        left: 0rem;
        z-index: 100;
    }

    .form-back {
        margin-top: 0rem;
        padding: 4rem;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 90%;
    }

    .drawer {
        margin-left: 0;
        width: 100%;
    }

    .hero p {
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    .go-up {
        margin-top: 2rem;
    }

    .hero span.upper {
        font-size: 1.3rem;
        padding-left: 0;
        margin-bottom: 1rem;
        line-height: 1rem;
    }

    h1 {
        font-size: 2.4rem;
        margin-bottom: 0.7rem;
        line-height: 2.5rem;
    }

    .hero p {
        line-height: 1.8rem;
        margin-bottom: 0;
        font-size: 1.1rem;
        padding: 0;
    }

    form {
        padding: 2rem;
    }

    .pt-6 {
        padding-top: 2rem;
    }

    hr {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .hero-content {
        padding: 2rem 1rem 3rem 1rem;
        background-color: #ffffff;
        margin-top: 0px;
    }

    .mt--8 {
        margin-top: 1rem;
    }

    .atmo-img {
        position: relative;
        left: 0rem;
        z-index: 100;
    }

    .form-back {
        margin-top: 0rem;
        padding: 3rem 2rem;
    }

}

@media (max-width: 575.98px) {

    .drawer {
        margin-left: 0;
        width: 100%;
        padding: 1rem 2rem 2rem;
        height: 100%;
    }

    form {
        margin-top: 0em;
        padding: 1.5rem 1rem;
    }

    footer {
        padding: 0 0 2rem;
    }

    footer h5 {
        font-size: 1.24rem;
        padding-top: 2rem;
    }

    footer h5 a {
        padding-top: 1rem;
        display: block;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .go-up {
        margin-top: 1rem;
    }

    .btn-lg {
        padding: 0.72rem 0.9rem;
        font-size: 0.9rem;
        line-height: 1.5;
        border-radius: 0;
    }

    .pl-6 {
        padding-left: 0 !important;
    }

    .pt-6 {
        padding-top: 1rem;
    }

    .hero span.upper {
        font-size: 1.3rem;
        padding-left: 0;
        margin-bottom: 1rem;
        line-height: 1rem;
    }

    h1 {
        font-size: 2.4rem;
        margin-bottom: 0.7rem;
        line-height: 2.5rem;
    }

    .hero p {
        line-height: 1.8rem;
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
    }

    .hero-content {
        padding: 0rem 1rem 3rem 1rem;
        background-color: #ffffff;
        margin-top: 0px;
    }

    .mt--8 {
        margin-top: -0.56rem;
    }

    .mb-8 {
        margin-bottom: 3rem;
    }

    .atmo-img {
        position: relative;
        left: 0rem;
        z-index: 100;
    }

    .form-back {
        margin-top: 0rem;
        padding: 3rem 2rem;
    }

    .calc {
        background-color: #EAEFF5;
        padding: 3rem 0 12rem;
        margin-bottom: 9rem;
    }

    .calc .number {
        font-size: 3rem;
        font-family: 'TiemposText-Medium';
        line-height: 1.8rem;
    }

}

.red {
    color: #cc0000;
}

.footer-links {
    padding-bottom: 1rem;
    a {
        padding-left: 1rem;
        &:first-of-type {
            padding-left: 0;
        }
    }
}

.presignin-wrapper {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.login-button {
    padding-bottom: 1rem;
    display: inline-block;
}

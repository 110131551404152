.formulate-input-element--submit {

    button {
        color: #ffffff;
        font-family: arboria, sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        border: 2px solid #54BAB3;
        background-color: #54BAB3;
        letter-spacing: 0.64px;
        padding: 1rem 2rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0;
        cursor: pointer;

        &:hover {
            border: 2px solid #ffffff;
            color: #fff;
            background-color: #1A262E;
        }
    }

}

.formulate-input-help--after {
    font-size: 0.88rem;
    font-weight: 400;
    color: #6c757d;
    margin: -18px 0 18px;
}

div[data-type="checkbox"] {
    .formulate-input-wrapper {
        display: flex;
        input {
            margin-right: 8px;
        }

    }
}
.formulate-input-wrapper {
    margin-bottom: 1.32rem;

    label {
        display: inline-block;
        margin-bottom: .25rem;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    input[name="terms"] {
        margin-right: 10px !important;
    }

    input[type=checkbox] {
        padding-right: 14px;
    }

    input {
        font-weight: 400;
        display: block;
        width: 100%;
        height: calc(2em + 1.5rem);
        padding: .5rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0;

        //&::placeholder {
        //    color: #54BAB3;
        //}
    }

    button {

    }

    input:focus,
    textarea:focus {
        border-color: #000;
    }

}

input[type="tel"] {
    display: block;
    width: 100%;
    height: calc(1.25em + 1.5rem);
    padding: 2.8rem 1rem;
    font-size: 3rem;
    font-weight: 500;
    color: #54bab3;
    background-color: #fff;
    font-family: "TiemposText-Medium";
    border: 1px solid #ced4da;
    border-bottom: 4px solid #54bab3;
    border-radius: 0;
    line-height: 1.5;
    outline: 0;

    &:focus {
        border-color: #000;
    }

    //&::placeholder {
    //    color: #54BAB3;
    //}
}

.formulate-input-element--checkbox {
    display: inline;
    label {
        text-transform: none;
    }
}

.formulate-input-errors {
    padding: 0;
    list-style-type: none;
    color: red;
    font-size: 1rem;
}

input[type='checkbox'] {
    position: relative;
    top: 5px;
    width: 22px;
    height: 22px;
    display: inline-block;
}

input {
    //&::placeholder {
    //    color: #54BAB3 !important;
    //}
}

.terms {
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 500;
}

.register-button {
    margin-left: 1rem;
}

.consulting {
    .formulate-input-wrapper {
        margin-bottom: 0;
    }
}

.call-me {
    .formulate-input-label {
        color: $white;
    }
    p {
        color: #fff;
    }
}

.formulate-form {
    font-family: arboria, sans-serif;
    font-weight: 500;
    font-style: normal;
}

textarea {
    display: block;
    width: 100%;
    height: auto;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0;
}
